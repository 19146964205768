const openFirst = ()=>{
    const elements = document.querySelector('.elementor-toggle .elementor-toggle-item .elementor-tab-title');

    if (elements){
        const evt = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        });

        elements.dispatchEvent(evt);
    }
}

export default function toggle_handler(){
    openFirst();
}